
import React from 'react';
import { RMap, ROSM, RLayerVector, RFeature, RPopup, RStyle } from 'rlayers';
import 'rlayers/control/layers.css';
import tourLinks from '../data/tour_links.json';
import { fromLonLat } from "ol/proj";
import "../components/walkabout-map.css";
import { Point } from "ol/geom";
import Layout from './layout';
import { Typography } from '@mui/material';
const MusicPage = () => {

 //   const [activePopup, setActivePopup] = useState(null);

    const allLinks = tourLinks.sections.map((s, i) => {
        const handleFeatureClick = (url) => {
            const embedUrl = url.replace("watch?v=", "embed/");
            const iframe = document.createElement('iframe');
            iframe.width = "560";
            iframe.height = "315";
            iframe.src = embedUrl;
            iframe.frameBorder = "0";
            iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
            iframe.allowFullscreen = true;
            const popupContent = document.querySelector('.rl-popup-content');
            popupContent.innerHTML = '';
            popupContent.appendChild(iframe);
        };

        return s.music.map((m, j) => {
            return {
                name: m.title,
                url: m.link,
                latitude: m.coords.lat,
                longitude: m.coords.long,
                onClick: () => handleFeatureClick(m.link)
            }
        });
    }).flat();

    // const pointEnterUseCallback = useCallback((e) => {
    //     setActivePopup(e.feature);
    // }
    // , []);
    // const pointLeaveUseCallback = useCallback((e) => {
    //     setActivePopup(null);
    // }
    // , []);

    const popup = React.useRef();

    //console.log(allLinks);
    return (
            <Layout   >
        <div>
            <h1>Walkabout with Bach Cello Suites</h1>
             <div style={{height: '500px', width: '95%', margin: '10px', border: '1px'}}>
             <RMap className="walkabout-map" initial={{ center: fromLonLat([-73.064, 41.45]), zoom: 14 }}>
                <ROSM />
                  <RLayerVector zIndex={10}>
                    {allLinks.map((link, index) => (
                        <RFeature key={index} 
                        ref={popup} 
                        
                        geometry={new Point(fromLonLat([link.longitude, link.latitude] ))}
                                >
                            <RPopup trigger="hover" className="walkabout-overlay"
                            autoPosition={true}
                             onClick={link.onClick}>


                            {link.name}
                              
                            </RPopup>

                        </RFeature>           
                    ))}
                <RStyle.RStyle>
                    <RStyle.RRegularShape points="4" radius="4" >
                    <RStyle.RFill color="black" />
                    <RStyle.RStroke color="black" />
                    </RStyle.RRegularShape>
                </RStyle.RStyle>
                </RLayerVector>
            </RMap>
            </div>
            <Typography variant="h2">A Cello Hike?</Typography>
            <Typography>
                To mark the summer solstice, I hiked the Walkabout with a cello on my back.  Over the course of a weekend,  I played the Bach Cello Suites at various locations along the Walkabout.   
                  The videos were all recorded with a 360 camera, so you can look around as I play.  I hope you enjoy the music and the scenery and that it will give you inspiration to make the journey yourself.
            </Typography>
            <div>

                <h2>Music Tour Locations</h2>
                <ul>
                    {allLinks.map((link, index) => (
                        <li key={index}>
                            <a href={link.url} target="_blank" rel="noopener noreferrer">{link.name}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
      </Layout>
    );
};

export default MusicPage;
