import React from 'react';
import Layout from './layout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ImageMapper from 'react-img-mapper';
import SignupModal from './signup';

const MAP = {
    name: "my-map", 
    areas: [
        { name: "nsfeb", shape: "rect", coords: [750,0,1300,350], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/nsfeb" },
        { name: "bethany", shape: "rect", coords: [1200,300,1350,800], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/bethany" },
        { name: "mesa", shape: "rect", coords: [1000,800,1400,1200], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)",  strokeColor: "transparent", href: "/mesa" },
        { name: "lantern", shape: "rect", coords: [700,800,950,1200], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/lantern_ridge" },
        { name: "main", shape: "rect", coords: [450,850,600,1200], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/main_st" },
        { name: "matthies", shape: "rect", coords: [0,900,200,1200], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/matthies" },
        { name: "nsfwb", shape: "rect", coords: [150,200,550,750], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/nsfwb" },

    ]
}

export default function Home() {

    const [open, setOpen] = React.useState(false);
    const [hikeDate, setHikeDate] = React.useState();

    const handleClick = (event) => {
        if (!event) { 
            return;
        }
        const hike = event?.target?.value;

        if (hike){
         setHikeDate( hike);
         setOpen(true);
         console.log(hike);
        }
    }
    return (
        <Layout   >
                  
      <Box sx={{ m: 2 }}> 
      <Typography  variant="h3" >Beacon Falls Walkabout</Typography>
      </Box>
      <Box sx={{ m: 2  }}>
        <Typography>
            Beacon Falls Walkabout is a project to encourage the exploration of the beautiful natural areas of Beacon Falls, CT.
            <br />
            We invite residents and visitors to explore the town's natural beauty by walking the Walkabout Path.
            <br />
            We will have a series of hikes on the first Saturday of each month, starting in May 2025.
            <br />
            Transport will be arranged back to the start point for each hike.  Signing up for the hike helps us estimate how many people will be attending and to arrange transport. 
            Please bring water and wear sturdy shoes.
            </Typography>
            <br />
            <Typography variant='h6'>Upcoming Hikes:</Typography>
    <Table aria-label="basic table">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '40%' }}>Section</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>RSVP?</TableCell>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
          <TableCell>Distance</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow id="1">
          <TableCell>Naugatuck SF East Block</TableCell>
          <TableCell>Saturday May 3, 2025 10:00 a.m</TableCell>
          <TableCell><Button value="2025-05-03" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
          <TableCell>Commuter Lot off Exit 24 (old exit 25)</TableCell>
          <TableCell>Van Epps Preserve Trailhead on Rt 42.</TableCell>
          <TableCell>Approx. 5 miles</TableCell>
        </TableRow>
        <TableRow id="2">
          <TableCell>Bethany Land Trust</TableCell>
          <TableCell>Saturday June 7, 2025 10:00 a.m.</TableCell>
          <TableCell><Button value="2025-06-07" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
          <TableCell>Mendels Folly Trailhead on Rt. 42</TableCell>
          <TableCell>Mesa Drive Trailhead</TableCell>
          <TableCell>Approx. 2 miles</TableCell>
        </TableRow>
        <TableRow id="3">
          <TableCell>Lantern Ridge</TableCell>
          <TableCell>Saturday July 5, 2025 10:00 a.m.</TableCell>
          <TableCell><Button value="2025-07-05" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
          <TableCell>Lantern Ridge Parking Lot on Skokorat Rd</TableCell>
          <TableCell>Starwood Lane Trailhead</TableCell>
          <TableCell>Approx. 3 miles</TableCell>
        </TableRow>
        <TableRow id="4">
          <TableCell>Naugatuck River Greenway</TableCell>
          <TableCell>Saturday August 2, 2025 10:00 a.m.</TableCell>
          <TableCell><Button value="2025-08-02" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
         <TableCell>Main Street by Depot Street Bridge</TableCell>
          <TableCell>Toby's Pond Parking Lot</TableCell>
          <TableCell>Approx. 3 miles</TableCell>
 
        </TableRow>
        <TableRow id="5">
          <TableCell>Matthies Park</TableCell>
          <TableCell>Saturday September 6, 2025 10:00 a.m.</TableCell>
          <TableCell><Button value="2025-09-06" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
          <TableCell>Matthies Park parking lot</TableCell>
          <TableCell>Pent Rd Recreation Field</TableCell>
          <TableCell>Approx 3 miles</TableCell>
        </TableRow>
        <TableRow id="6">
          <TableCell>Pent Road to High Rock</TableCell>
          <TableCell>Saturday October 4, 2025 10:00 a.m.</TableCell>
          <TableCell><Button value="2025-10-04" variant="outlined" onClick={handleClick} >Sign Up</Button></TableCell>
          <TableCell>Pent Rd Recreation Field</TableCell>
          <TableCell>Cold Spring Rd Parking Lot</TableCell>
          <TableCell>Approx 5 miles</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  
        <SignupModal hikeDate={hikeDate} open={open} setOpen={setOpen}  />
    
        {/* <Typography>Beacon Falls Walkabout is a project to encourage the exploration of the beautiful natural areas of Beacon Falls, CT.</Typography>
        <Typography>The town is surrounded on all sides by State Forest, Town land, and other conservation land.   </Typography>
        <Typography>But, the town has not done a great job helping residents with information on how to access these lands or where to begin.</Typography>
        <Typography>By laying out a route around the town, we challenge residents to get to know the WHOLE town!</Typography>
        <Typography>For the most fit, this route could be done in a day. </Typography>
        <Typography>For the rest of us, plan to enjoy it in sections and keep going section by section until you have made the complete circuit.</Typography>
        <Typography>Most of the proposed route is over existing trails on State Forest or Town lands.</Typography> 
        <Typography>We have tried to minimize road walking, but roads are the only way to get across the river at the moment.</Typography>  
        <Typography>Planning this route has made me realize how much potential the town has 
            in the beauty of its natural resources </Typography>
        <Typography>
            and how much more could be done to improve the accessibility.  </Typography>
        <Typography>
            From the hills to the river, Beacon Falls has a stunning number of natural places. 
            </Typography>
        <Typography> If you'd like to get involved in helping us to make this walkabout a reality, 
            check out the <Link to="/planning">planning</Link> page.</Typography> */}
      </Box>
      <Box sx={{ display: 'inline-flex', m: 2, border: 1 }}>
      <ImageMapper src="img/home/route_overview_4.png" alt="Beacon Falls" map={MAP} />

      </Box>
      <Box sx={{ m: 4 }}> <Typography>Get Started: &nbsp; <Link to="/nsfeb" key="nsfeb" >Naugatuck State Forest East Block</Link>
              </Typography>
              </Box>
        </Layout>
  
    );
}