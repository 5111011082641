import React from 'react';
import Layout from './layout';
import Map from '../components/map';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
//import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
export default function Explore() {
    return (
    <Layout   >
        <Box><Typography variant="h2">Walkabout Path</Typography></Box>          
        <div style={{height: '500px', width: '95%', margin: '10px', border: '1px'}}>
            <Map />
        </div>
        {/* <Box><Typography>Legend</Typography>
        <List>
            <ListItem> Walkabout Path</ListItem>
            <ListItem> Laurel Ledge Spur Path</ListItem>
            <ListItem>39 Acre Spur Path</ListItem>
            <ListItem>Lorraine Rd Spur Path</ListItem>
            <ListItem>Lopus Rd Ext Spur Path</ListItem>
            <ListItem>Rail Trail Paths</ListItem>
            </List></Box> */}
    </Layout>
  
    );
}