import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';


const SignupModal = ({ open, setOpen}) => {
 
    const signupUrl = "https://uh1q46b5bh.execute-api.us-east-1.amazonaws.com/dev/emailSignup";
    let signupCount = 0;
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    const postData = async (email, name, hikes) => {    
        try {
             await axios.post(signupUrl, {
                email: email,
                name: name,
                hikeIds: hikes,
            }, config).then((response) => {
                    signupCount = response.body;
            });
           return signupCount; 
        } 
        catch (error) {
            console.error(error);
        }  

    } 
    const [snackOpen, setSnackOpen] = useState(false);  
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [hikes, setHikes] = useState([]);
    const handleClose = () => setOpen(false);

    const handleSnackClose = () => {    
        setSnackOpen(false);
    };
    const handleCheckboxChange = (e) => {
        if (!hikes.includes(e.target.value)) {
            setHikes([...hikes, parseInt(e.target.value)]);
        }
        
    }

    const handleEmailChange = (e) => { 
        setEmail(e.target.value);
    }
    const handleNameChange = (e) => { 
        setName(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle email submission logic here
        console.log('Email submitted:', email);
        console.log("Hike Dates:", hikes);
        console.log("Name: ", name);
        const successCount = postData(email, name, hikes);
        setHikes([]);
        if (successCount > 0) {
            snackOpen(true);
        }
        handleClose();
    };

    return (
        <>
 <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries(formData.entries());
            // const email = formJson.email;
            // setEmail(email);

            // console.log(email);
            // console.log("Hikes: ", hikes);
            // invoke lambda to add email to postgres db for hikeDate
            handleClose();
          },
        }}
      >
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Sign up for a reminder for this hike.
          </DialogContentText>

          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={handleEmailChange}
          />
        <TextField
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={handleNameChange}
          />
        <FormGroup>
        <FormControlLabel
                control={<Checkbox name="hike_1" onChange={handleCheckboxChange} value="1"/>}
                label="May 3, 2025" />
        <FormControlLabel
                control={<Checkbox name="hike_2" onChange={handleCheckboxChange} value="2"/>}
                label="June 7, 2025" />
        <FormControlLabel
                control={<Checkbox name="hike_3" onChange={handleCheckboxChange} value="3"/>}
                label="July 5, 2025" />  
        <FormControlLabel
                control={<Checkbox name="hike_4" onChange={handleCheckboxChange} value="4"/>}
                label="August 2, 2025" />   
        <FormControlLabel
                control={<Checkbox name="hike_5" onChange={handleCheckboxChange} value="5"/>}
                label="September 6, 2025" />   
        <FormControlLabel
                control={<Checkbox name="hike_6" onChange={handleCheckboxChange} value="6"/>}
                label="October 4, 2025" />    
        </FormGroup>
       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Sign Up</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message="Signup Successful"
      />
        </>
    );
};

export default SignupModal;